* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  padding: 0 20px;
  max-width: 600px;
  margin: 0 auto;
}

.map-container {
  height: calc(100vh - 72px - 44px);
}